#navi-bar {
	padding: 1% 5%;
	background-color: #f9f9f9;
}

#shopify-button {
	background-color: #010203;
	color: #f9f9f9;
	border-radius: 2rem;
	width: 100px;
}

#shopify-button.active,
#shopify-button:hover {
	background-color: #78be6e;
	color: #010203;
	width: 100px;
}
