#home {
	background-image: url("https://firebasestorage.googleapis.com/v0/b/moratia-games.appspot.com/o/Moratia_Games_b.png?alt=media&token=e69cfbaf-3278-457c-a64d-73c5582b08c5");
}

#logo-container {
	height: 100vmin;
}

#landing-logo {
	height: 90%;
	padding-top: 25px;
}
