#footer{
    background-color: #010203;
    color: #f9f9ff;
    padding: 2% 5%;
}

#footer--left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: right;
    align-items: center;
}

#footer-center{
    font-size: small;
    align-items: center;
}
#footer--right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
    align-items: center;
}

.footer-link:link {
    color: #f9f9ff;
    text-decoration: none;
}

.footer-link:visited {
    color: #f9f9ff;
    text-decoration: none;
}

.footer-link:hover {
    color: #BABABA;
    text-decoration: underline;
}

#modal-form-login-buttons{
    display: flex;
    justify-content: space-around;
}

#modal-title {
    color: red;
}