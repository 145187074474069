.updates-forms {
    font-weight: bold;
    font-family: Poppins;
    font-size: 20px;
}

@media only screen and (max-width: 768px) {
    .updates-forms {
        font-size: 18px;
        /* Adjust the font size as per your preference for mobile devices */
    }
}