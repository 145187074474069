#updates {
	background-color: #010203;
	color: #f9f9ff;
	display: flex;
}

#updates-left {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 5%;
}

#updates-right {
	padding: 10% 9%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
