#products{
    background-color: #BABABA;
}

#product--text-box--entry {
    display: flex;
    flex-direction: column;
    grid-template-rows: 25px 1fr;
    margin-bottom: 50px;
}

#product--title{
    text-transform: uppercase;
    
}
#product-right {
    padding: 5% 15% 5% 9%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#product-left {
    padding: 5% 15% 5% 20%;
    display: flex;
    justify-content: center;
}

#product-image {
    height: 75vmin;
}

.image-size{
    size: 25%;
}