#gallery{
    background-color: #BABABA;
    /* color: #f9f9ff; */
}

#gallery-container{
    grid-template-rows: 1fr 10fr;
    padding: 2% 5% 2% 5%;
}

#gallery--top {
    text-align: center;
}

#gallery--bottom {
    overflow-y: scroll;
    height: 80vmin;
    padding-right: 10px;
}