#world{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8adae9;
    /* width: 90vmax; */
    
    /* background-image: url(../images/IMG_4098.PNG); */
    /* color: #f9f9ff; */
}

/* #world:hover{
    background-color: rgb(243, 251, 253);
    opacity: 10%;
} */

#world--map{
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 20% 10% 20% 5%; */
}

#world--map img{
    max-width:100%;
    max-height:100%;
}

/* #world--map-image:hover{
    opacity: 10%;
} */

#world--map--text{
    position: absolute;
    /* right: 50%;
    left: 50%;
    bottom: 15%; */
    width: 75%;
    height: 100%;
    opacity: 0%;
}

#world--map--text:hover{
    background-color: rgba(243, 251, 253, .9);
    opacity: 100%;
    /* width: 1000px; */
    /* height: 90%; */
    /* border-radius: 10px; */
}