#about-us{
    background-color: #010203;
    color: #f9f9ff;
}

#about-us--text-box--entry {
    display: flex;
    flex-direction: column;
    grid-template-rows: 25px 1fr;
    margin-bottom: 50px;
}

#about-us--title{
    text-transform: uppercase;
    
}
#about-us--right {
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: scroll;
    /* height: 400px; */
}

#about-us--left {
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#about-us--image {
    height: 40vmin;
}

.image-size{
    size: 25%;
}