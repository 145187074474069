#world-text{
    padding: 8% 5% 10% 5%;
    display: flex;
    flex-direction: column;
}

#world--text-box{
    overflow-y: scroll;
    height: 50vmax;
    padding-right: 10px;
}

#world--headers-text {
    font-weight:bolder;
    font-family: Poppins;
    font-size:70px;
    text-align: center;
    text-transform: uppercase;
}

@media (max-width: 1024px) {
    #world--headers-text {
        font-size: 60px;
    }
}

@media (max-width: 768px) {
    #world--headers-text {
        font-size: 50px;
    }    
}    

@media (max-width: 480px) {
    #world--headers-text {
        font-size: 2rem;
    }        
}        

/* width */
#world--text-box::-webkit-scrollbar {
    width: 10px;
}

/* Track */
/* #world--text-box::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
} */

/* Handle */
#world--text-box::-webkit-scrollbar-thumb {
    background: #8adae9; 
    border-radius: 10px;
}

/* Handle on hover */
#world--text-box::-webkit-scrollbar-thumb:hover {
    background: #bababa; 
}