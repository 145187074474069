#video {
	background-color: #f9f9f9;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100vh;
	padding-top: 2rem;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
	max-width: 60% !important;
}
