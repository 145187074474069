#kickstarter{
    background-color: #f9f9f9;
    display: flex;
    padding: 5%;
}

#kickstarter-right--top {
    font-weight: bold;
    font-family: Poppins;
    font-size: 25px;
} 

#kickstarter-left {
    display: flex;
    flex-direction: column;
    /* padding: 7% 5%; */
    padding: 0 10%;
    text-align: center;
}

#kickstarter-right--top {
    display: flex;
    justify-content: center;
}

#kickstarter-right {
    /* padding: 7% 5%; */
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#kickstarter-logo-image {
    width: 50%;
    text-align: center;
    border-color: #000000;
    border-style: solid;
    padding: 15px;
    border-radius: 15px;
}

#kickstarter-logo-image:hover {
    background-color: #222222;
}

#countdown-container {
    width: 80%;
}

#countdown-timer {
    display: flex;
    justify-content: center;

}

#kickstarter-right--title {
    text-align: center;
    font-weight: bold;
    font-family: Poppins;
    font-size: 25px;
    margin-top: 25px;
}

#kickstarter-right--date {
    text-align: center;
}

#kickstarter-right--top--text {
    text-align: center;
    color: #05ce78;
}